import React, { useState, useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Fade from "react-reveal/Fade"
import CrossfadeImage from "react-crossfade-image"
import Slider from "react-slick"
import BlockCta from "src/components/Blocks/BlockCta"
import BlockConfigurations from "src/components/Blocks/BlockConfigurations"
import Breadcrumbs from "src/utils/Breadcrumbs"
import ScrollLock, { TouchScrollable } from "react-scrolllock"

const WoodsAndFinishesSelector = props => {
  const settings = {
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }

  function NextArrow(props) {
    const { onClick } = props
    return (
      <div
        className={
          "absolute -right-6 top-1 flex justify-center items-center w-6 h-6"
        }
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={onClick}
      >
        <i className="fas text-xs fa-chevron-right" aria-hidden></i>
      </div>
    )
  }

  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div
        className={
          "absolute -left-6 top-1 flex justify-center items-center w-6 h-6"
        }
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={onClick}
      >
        <i className="fas text-xs fa-chevron-left" aria-hidden></i>
      </div>
    )
  }

  // Detect when user scrolls to bottom of page so we can hide the woods strip
  const [scrolled, setScrolled] = useState(false)
  // Trigger the opened mobile wood chooser
  const [opened, openWoods] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", function () {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight ||
        window.scrollY > props.position
      ) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    })
  })

  const [mobileSelectedImage, setMobileSelectedImage] = useState("")

  // =================
  // URL FILTER START
  // =================

  // This function will change the URL to the current selected filter for simple use
  const changeUrlQuery = param => {
    const urlParams = new URLSearchParams(window.location.search.slice[1])
    urlParams.set("wood-finish", param)
    window.history.replaceState({}, "", "?" + urlParams)
  }

  const handleClick = selection => {
    props.updateSelection(selection, props.state)
    if (selection !== "All") {
      changeUrlQuery(selection)
      setMobileSelectedImage(
        props.data.find(item => item.node.data.title.text === selection).node
          .data.featured_image.url
      )
    }
  }

  // Check for URL query param and toggle those doors
  useEffect(() => {
    // Get the param from the URL
    const params = new URLSearchParams(window.location.search.slice(1))
    let woodFinishQuery = params.getAll("wood-finish")[0]

    // Loop through all of the doors and store the titles in an array
    let titles = []
    props.data.map(item => {
      titles.push(item.node.data.title.text)
      return null
    })

    // Look through the title array for a match from the URL param
    const matchTitle = titles.find(title => title === woodFinishQuery)

    // Change the filter
    handleClick(matchTitle ? matchTitle : "All")
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // Disable the stupid warning

  // =================
  // URL FILTER END
  // =================

  return (
    <div>
      {/* Desktop */}
      <div
        className={`sticky-nav bg-white fixed w-full z-40 transition-all duration-500 ease-in-out hidden lg:block ${
          scrolled ? "-bottom-16" : "bottom-0"
        }`}
      >
        <div className="px-14 py-4 flex justify-between items-center">
          <div className="relative justify-start items-center mr-auto flex flex-row">
            <span className="text-darkgrey font-bold text-xs tracking-widest uppercase">
              Choose a wood or finish
            </span>
            <span className="mx-4">|</span>
            <div
              className="underline text-sm cursor-pointer italic"
              role="button"
              onClick={() => handleClick("All")}
              onKeyDown={() => handleClick("All")}
              tabIndex={0}
            >
              See all door styles
            </div>
          </div>
          <div className="slider w-80 relative">
            <Slider {...settings} className="h-9">
              {props.data.map((item, index) => {
                return (
                  item.count > 0 && (
                    <div
                      className="px-2.5 focus:outline-none"
                      onClick={() => handleClick(item.node.data.title.text)}
                      onKeyDown={() => handleClick(item.node.data.title.text)}
                      role="button"
                      tabIndex={index}
                      key={index}
                    >
                      <div
                        className={`rounded-full overflow-hidden focus:outline-none p-px border cursor-pointer ${
                          props.selected === item.node.data.title.text
                            ? "border-gray-500"
                            : ""
                        }`}
                        role="button"
                        tabIndex={0}
                      >
                        <img
                          className="rounded-full w-full"
                          src={item.node.data.featured_image.url}
                          alt={item.node.data.title.text}
                        />
                      </div>
                    </div>
                  )
                )
              })}
            </Slider>
          </div>
          <div className="selected w-72 ml-auto">
            <div className="text-right flex justify-end items-center ">
              <h4 className="text-darkgrey text-xs">
                Selected:{" "}
                <span className="text-darkgrey font-bold text-xs tracking-widest uppercase">
                  {props.selected}
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div
        className={`sticky-nav bg-white fixed w-full ${
          opened ? "z-[9999999999]" : "z-30"
        } transition-all duration-500 ease-in-out lg:hidden block ${
          opened ? "-top-14" : "top-woods-default"
        } ${scrolled ? "-bottom-full top-full" : "bottom-0"}`}
      >
        <div
          className="px-7 py-4 flex justify-end items-center bg-black cursor-pointer"
          onClick={() => openWoods(true)}
          onKeyDown={() => openWoods(true)}
          role="button"
          tabIndex={0}
        >
          <div
            className="absolute left-1/2 transform -translate-x-1/2"
            style={{ minWidth: 211 }}
          >
            <span className="flex text-white font-semibold text-xs tracking-widest uppercase whitespace-nowrap items-center">
              {mobileSelectedImage && (
                <img
                  src={mobileSelectedImage}
                  alt="Selected wood or finish"
                  className="w-8 h-8 mr-2 border-2 border-[#4e4d4b] rounded-full"
                />
              )}
              Choose a wood or finish
            </span>
          </div>
          <div className="selected">
            <div className="text-right text-lg text-gold flex justify-end items-center ">
              <i className="fal fa-chevron-up" aria-hidden></i>
            </div>
          </div>
        </div>
        <ScrollLock isActive={opened} />
        <TouchScrollable>
          <div className="bg-white px-12 py-8 text-center h-screen overflow-y-scroll relative">
            <h4 className="text-darkgrey font-bold text-xs tracking-widest uppercase">
              Choose from our
              <br />
              popular woods &amp; colours
            </h4>
            <button
              className="absolute block top-8 right-4 p-2 px-4 outline-none focus:outline-none"
              onClick={() => openWoods(false)}
            >
              <i className="fal fa-times" aria-hidden></i>
            </button>
            <div className="w-6 separator mt-4 bg-darkgrey"></div>
            <div className="w-full separator mt-4 bg-darkgrey"></div>
            <div className="grid grid-cols-3 gap-6 my-6">
              {props.data.map((item, index) => {
                return (
                  <div
                    onClick={() => openWoods(false)}
                    onKeyDown={() => openWoods(false)}
                    role="button"
                    tabIndex={0}
                    key={index}
                  >
                    <div
                      className={`rounded-full w-16 h-16 m-auto overflow-hidden p-px border cursor-pointer focus:outline-none ${
                        props.selected === item.node.data.title.text
                          ? "border-gray-500"
                          : ""
                      }`}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleClick(item.node.data.title.text)}
                      onKeyDown={() => handleClick(item.node.data.title.text)}
                    >
                      <img
                        className="rounded-full w-full"
                        src={item.node.data.featured_image.url}
                        alt={item.node.data.title.text}
                      />
                    </div>
                    <h4 className="text-darkgrey mt-4 font-bold text-tiny tracking-widest uppercase">
                      {item.node.data.title.text}
                    </h4>
                  </div>
                )
              })}
            </div>
          </div>
        </TouchScrollable>
      </div>
    </div>
  )
}

const ArchiveListing = props => {
  return (
    <section className="container pt-12 pb-0 lg:pb-12 internal-door-styles-items">
      <div className="grid grid-cols-2 lg:grid-cols-3">
        {props.data.map((item, index) => {
          return (
            <ArchiveListingItem
              data={item}
              key={index}
              selected={props.selected}
            />
          )
        })}
      </div>
    </section>
  )
}

const ArchiveListingItem = props => {
  var image = ""

  if (props.selected === "All") {
    image = props.data.images[0]
  } else {
    image = props.data.images.find(image => image.selected === true)
  }

  if (!image || (image && image.src === null)) {
    return null
  }

  return (
    image && (
      <Link
        to={`/internal-doors/styles/${props.data.uid}/`}
        className="group relative w-full p-2 text-left mb-0"
        title={`${props.data.title}`}
      >
        <Fade>
          <div className="door-images w-full relative bg-white">
            <CrossfadeImage
              src={image ? image.src : ""}
              alt={props.data.title}
              containerClass={`absolute top-0 left-0 w-full flex justify-center items-end transition duration-500 ease-in-out h-full`}
            />
            {props.data.featured_image && (
              <img
                src={props.data.featured_image}
                alt={props.data.title}
                className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition duration-1000 ease-in-out"
              />
            )}
          </div>
        </Fade>
        <Fade>
          <h2 className="text-darkgrey font-bold text-xs tracking-widest uppercase my-4 group-hover:text-gold duration-500 ease-in-out">
            {props.data.title}
          </h2>
        </Fade>
      </Link>
    )
  )
}

const ArchiveInternalDoor = props => {
  const defaultWood = "All"
  const [selected, setSelected] = useState("All")

  const doorData = props.data.allPrismicInternalDoor.edges.map(
    (item, index) => {
      const images = item.node.data.woods_and_finishes.map((item2, index2) => {
        return {
          uid: item2.wood_or_finish.uid,
          name: item2.wood_or_finish.document
            ? item2.wood_or_finish.document.data.title.text
            : defaultWood,
          src: item2.door_image.url,
          selected:
            selected === item2.wood_or_finish.document
              ? item2.wood_or_finish.document.data.title.text
              : defaultWood
              ? true
              : false,
        }
      })
      return {
        uid: item.node.uid,
        title: item.node.data.title.text,
        images: images,
        featured_image: item.node.data.featured_image.url,
      }
    }
  )

  // map the number of doors to each wood or finish
  const woods_and_finishes = props.data.allPrismicWoodsAndFinishes.edges.map(
    (item, index) => {
      return {
        node: item.node,
        count: doorData.filter(door =>
          door.images.find(image => image.name === item.node.data.title.text)
        ).length,
      }
    }
  )

  const [doorState, setDoorState] = useState(doorData)

  const updateSelection = (selection, data) => {
    const newSelection = data.map((item, index) => {
      const images = item.images.map((item2, index2) => {
        return {
          uid: item2.uid,
          name: item2.name,
          src: item2.src,
          selected: selection === item2.name ? true : false,
        }
      })
      return {
        uid: item.uid,
        title: item.title,
        images: images,
        featured_image: item.featured_image,
      }
    })
    setDoorState(newSelection)
    setSelected(selection)
  }

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    className: "center",
  }

  // Get the height of the first section so we can pass it down
  // to the trigger for the bottom strip
  const [position, setPosition] = useState(0)
  const stipRef = useRef()

  useEffect(() => {
    const updateHeight = () => {
      setPosition(stipRef.current.clientHeight)
    }

    updateHeight()

    window.addEventListener("resize", updateHeight)
  })

  const crumbs = [
    { label: "Internal Doors", href: "/internal-doors" },
    { label: "Door Styles", href: "/internal-doors/styles", active: true },
  ]

  return (
    <div className="internal-door-style">
      <Seo
        title={"Internal Door Styles"}
        description={
          "Our modern luxury internal doors instantly become prominent features wherever they’re situated. Discover how we can help carry your style throughout your home."
        }
      />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <div ref={stipRef}>
        <section className="container pb-0">
          <div className="flex flex-row">
            <div className="mx-auto text-center">
              <Fade>
                <h2 className="font-display text-gold rfs:text-6xl">
                  Internal Door Styles
                </h2>
                <div className="w-12 separator mt-5"></div>
                <p className="max-w-xl m-auto mt-5 text-sm">
                  We craft exquisite, modern internal doors in both contemporary
                  and classic styles, using the highest-grade timber from a
                  single tree to ensure unrivalled quality and aesthetic.
                </p>
                <p className="max-w-xl m-auto mt-5 text-sm">
                  Whether it’s a pre-hung set or a dramatic pivot door, you can
                  choose from a range of beautiful finishes to perfectly match
                  the tone and design of your home, including grey oak, walnut,
                  olive ash and many more.
                </p>
                <p className="max-w-xl m-auto mt-5 text-sm">
                  A luxury{" "}
                  <Link to="/internal-doors/made-to-measure-internal-doors/">
                    made-to-measure
                  </Link>{" "}
                  internal door is more than an entrance; it’s a statement.
                </p>
              </Fade>
            </div>
          </div>
        </section>
        <ArchiveListing data={doorState} selected={selected} />
      </div>
      <section className={`bg-offwhite`}>
        <div className={`container`}>
          <div className={`flex flex-col lg:flex-row-reverse justify-between`}>
            <Fade direction="up">
              <div className="lg:w-5/12 w-full lg:pl-24 pl-0">
                <h1 className="font-display text-gold rfs:text-5xl">
                  Bespoke Door Designs
                </h1>
                <div className="w-12 mt-5 separator"></div>
                <div className="mt-5 text-sm">
                  <p>
                    We believe internal doors should be more than just
                    functional – they should add a stylish accent to the room.
                    Our bespoke design service means your vision can be made in
                    any size, wood or colour to beautifully blend in with your
                    existing decor. What’s more, we can craft doors to almost
                    any configuration – from discreetly sliding pocket doors to
                    space-saving bi-folding doors.
                  </p>
                </div>
                <Link to={`/internal-doors/bespoke/`} className="btn mt-10">
                  Find out more
                </Link>
              </div>
            </Fade>
            <Fade distance="20px" bottom>
              <div className="lg:w-7/12 lg:order-none order-first lg:pb-0 pb-24">
                <Slider {...settings}>
                  <img
                    src="https://images.prismic.io/deuren/0894209a-7eb3-4c88-aa34-1d55c0b185ae_Deuren-internal-bespoke-doors-1000-600-1.jpg?auto=compress,format"
                    alt="Deuren internal bespoke doors"
                  />
                  <img
                    src="https://images.prismic.io/deuren/9225ae29-2029-4163-88cc-bfb75c63baa0_Deuren-internal-bespoke-doors-1000-600-2.jpg?auto=compress,format"
                    alt="Deuren internal bespoke doors"
                  />
                  <img
                    src="https://images.prismic.io/deuren/776441f4-bd18-4fac-a6d8-9649ba1af3c1_Deuren-internal-bespoke-doors-1000-600-3.jpg?auto=compress,format"
                    alt="Deuren internal bespoke doors"
                  />
                  <img
                    src="https://images.prismic.io/deuren/97ce5a7e-6f6d-41ad-9ad4-63c63949cad3_Deuren-internal-bespoke-doors-1000-600-4.jpg?auto=compress,format"
                    alt="Deuren internal bespoke doors"
                  />
                  <img
                    src="https://images.prismic.io/deuren/1205ec4e-2390-4d4e-bbe7-58dcbf048745_Deuren-internal-bespoke-doors-1000-600-5.jpg?auto=compress,format"
                    alt="Deuren internal bespoke doors"
                  />
                  <img
                    src="https://images.prismic.io/deuren/b3d04ce3-69ec-43eb-ac00-50de24efd259_Deuren-internal-bespoke-doors-1000-600-6.jpg?auto=compress,format"
                    alt="Deuren internal bespoke doors"
                  />
                  <img
                    src="https://images.prismic.io/deuren/44f3aafe-ed46-4f7e-b8bf-7b968f79474f_Deuren-internal-bespoke-doors-1000-600-7.jpg?auto=compress,format"
                    alt="Deuren internal bespoke doors"
                  />
                </Slider>
              </div>
            </Fade>
          </div>
        </div>
      </section>
      <BlockConfigurations props={`internal-doors`} />
      <WoodsAndFinishesSelector
        data={woods_and_finishes}
        position={position}
        selected={selected}
        state={doorState}
        updateSelection={updateSelection}
      />
      <BlockCta />
    </div>
  )
}

export default ArchiveInternalDoor

export const ArchiveInternalDoorQuery = graphql`
  query ArchiveInternalDoorQuery {
    allPrismicInternalDoor(sort: { fields: data___custom_order, order: DESC }) {
      edges {
        node {
          data {
            title {
              text
            }
            woods_and_finishes {
              wood_or_finish {
                uid
                document {
                  ... on PrismicWoodsAndFinishes {
                    data {
                      title {
                        text
                      }
                      featured_image {
                        url
                      }
                    }
                  }
                }
              }
              door_image {
                url
              }
            }
            featured_image {
              url
              fluid(srcSetBreakpoints: [400, 800]) {
                srcSet
              }
            }
            door_type
            listing_image {
              url
              alt
            }
          }
          uid
        }
      }
    }
    allPrismicWoodsAndFinishes(
      filter: { data: { door_type: { eq: "Internal Door" } } }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            featured_image {
              url(imgixParams: { w: 60, h: 60 })
            }
          }
          uid
        }
      }
    }
  }
`
